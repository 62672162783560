import { useEffect, useState } from 'react'
import '../2d/styles/popup.css'

export let Message = ({message,time,type})=>{
    let [current,setCurrent] = useState(type)
    useEffect(()=>{
        setTimeout(() => {
            setCurrent('')
        }, (time*1000));
    })
    return (<>
                { 
                    current === "success"&& <div class="success alert">
                            <div class="content">
                            <div class="icon">
                            </div>
                            <p>{message}</p>
                            </div>
                            <button class="close">
                            </button>
                        </div>
                 }
                 { 
                    current==='info' && <div class="info alert">
                        <div class="content">
                        <div class="icon">

                        </div>
                        <p>{message}</p>
                        </div>
                        <button class="close">
                        </button>
                    </div>}
               {
                    current === 'warning'&& <div class="warning alert">
                        <div class="content">
                        <div class="icon">
                        </div>
                        <p>{message}</p>
                        </div>
                        <button class="close">
                        </button>
                    </div>
                }

               {
                current === 'danger' &&<div class="danger alert">
                        <div class="content">
                        <div class="icon">
                        </div>
                        <p>{message}</p>
                        </div>
                        <button class="close">
                        </button>
                    </div>
                }
    </>)
}
