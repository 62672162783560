import { useGraph } from "@react-three/fiber"
import { useMemo, useRef ,useEffect, useState} from "react"
import { useGLTF } from "@react-three/drei"
import { SkeletonUtils } from "three-stdlib"
import * as THREE from 'three'
import { Html } from "@react-three/drei"
import { TextureLoader } from "three"
import { Message } from "./2d/pop-up"
export const CubeItem = (props)=>{
    return <mesh castShadow {...props}>
        <boxGeometry args={[0.5, 0.5, 0.5]} />
        <meshStandardMaterial color="indianred" />
  </mesh>
}
export const SphereItem = (props)=>{
    return <mesh castShadow {...props}>
    <sphereGeometry></sphereGeometry>
    <meshStandardMaterial color="indianred"></meshStandardMaterial>
  </mesh>
}


export const Viewer = (props)=>{
  let material = useRef()
  let [textureUrl,setTextureUrl] = useState('')
  var loadFile = function(event) {
    let output = {}
    output.src = URL.createObjectURL(event.target.files[0]);
    setTextureUrl(output.src)
}  
useEffect(() => {
    const textureLoader = new TextureLoader()
    textureLoader.load(textureUrl, (t) => {
      console.log(t,'t')
      material.current.map = t
      material.current.needsUpdate = true
    })
    console.log(material)
}, [textureUrl])
  let materialso = new THREE.MeshPhysicalMaterial({color:''})
  const {nodes, materials }  = useGLTF('viewer.glb')
  return <group {...props}  rotation={[-1.6 ,0,0]}>
      {
        textureUrl ===''&&  <Html scale={0.5} position={[0.5,-0.1,1.5]} className="content" rotation-x={Math.PI / 2}transform occlude>
        <input type="file" style={{zIndex:1000}} accept="image/*" onChange={(event)=>loadFile(event)} />
        </Html>
      }
     <mesh castShadow receiveShadow geometry={nodes.Plane001.geometry}>
       <meshStandardMaterial ref={material} />
     </mesh>
     <mesh castShadow receiveShadow geometry={nodes.shadeBeige001.geometry} material={materialso} />
     <mesh castShadow receiveShadow position={[-1,0.2,0.5]} material={materialso}  geometry={nodes.shadeBrown002.geometry} />
     <mesh castShadow receiveShadow position={[1,0.2,0.5]} material={materialso} geometry={nodes.shadeBrown006.geometry} />
  </group>
}

export const FacebookSocialIcon = (props)=>{
  const {nodes }  = useGLTF('3d-social-icons.glb')
  return <group  {...props}>
    <mesh scale-y={0.5} castShadow receiveShadow rotation={[Math.PI/2,0,0]} position={[-2.2,-1,0]} geometry={nodes.facebook.geometry}>
      <meshPhysicalMaterial color={'#1877F2'}></meshPhysicalMaterial>
    </mesh>
  </group>
}

export const InstagramSocialIcon = (props)=>{
  const {nodes }  = useGLTF('3d-social-icons.glb')
  return <group  {...props}>
    <mesh castShadow receiveShadow rotation={[Math.PI/2,0,0]} position={[-1.2,-1,0]} geometry={nodes.instagram.geometry}>
      <meshPhysicalMaterial color={'#E1306C'}></meshPhysicalMaterial>
    </mesh>
  </group>
}

export const WhatsappSocialIcon = (props)=>{
  const {nodes }  = useGLTF('3d-social-icons.glb')
  return <group  {...props}>
    <mesh castShadow receiveShadow rotation={[Math.PI/2,0,0]} position={[1.1,-1,0]} geometry={nodes.whatsapp.geometry}>
      <meshPhysicalMaterial color={'#51B75A'}></meshPhysicalMaterial>
    </mesh>
  </group>
}
export const YoutubeSocialIcon = (props)=>{
  const {nodes }  = useGLTF('3d-social-icons.glb')
  return <group  {...props}>
    <mesh castShadow receiveShadow rotation={[Math.PI/2,0,0]} position={[2.3,0.9,0]} geometry={nodes.youtube.geometry}>
      <meshPhysicalMaterial color={'#FF0000'}></meshPhysicalMaterial>
    </mesh>
  </group>
}